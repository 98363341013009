<template>
  <div class="tire">
    <el-table
      border
      :lazy="true"
      :data="tiresDescription"
      style="width: 100%">
      <el-table-column
        label="Поле"
        prop="label" />
      <el-table-column
        label="Значение"
        prop="value">
        <template slot-scope="scope">
          {{ isBoolean(scope.row.value) ? hasString(scope.row.value) : scope.row.value }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import { mapMutations } from 'vuex';

import { hasString, isBoolean } from '@/utils';
import dataTable from './data';

export default {
  name: 'Tires',
  data() {
    return {
      tiresDescription: dataTable,
    };
  },
  mounted() {
    this.setPageTitle('Шина');
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    hasString,
    isBoolean,
  },
};
</script>
<style lang="scss">
</style>
