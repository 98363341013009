export default [
  {
    key: 'id',
    label: 'ID',
    value: '23123',
  },
  {
    key: 'tire_model_id',
    label: 'ID Модели',
    value: '78965',
  },
  {
    key: 'bx_id',
    label: 'Битрикс ID',
    value: '67543',
  },
  {
    key: 'tire_param_speed_symbol_id',
    label: 'Символ Скорости',
    value: '4543',
  },
  {
    key: 'tire_param_load_index_id',
    label: 'Индекс нагрузки',
    value: '321',
  },
  {
    key: 'the_param_width_id',
    label: 'Ширины профиля',
    value: '100',
  },
  {
    key: 'tire_param_height_id',
    label: 'Высота профиля, %',
    value: '35',
  },
  {
    key: 'tire_param_diameter_id',
    label: 'Посадочный диаметр, дюйм',
    value: '19',
  },
  {
    key: 'tire_param_carcass_id',
    label: 'Каркас шины',
    value: 'Радиальный',
  },
  {
    key: 'tire_param_load_type_id',
    label: 'Тип нагрузки',
    value: 'Обычная нагрузка',
  },
  {
    key: 'has_tubeless',
    label: 'TL, Tubeless - "Не имеющий камеры"',
    value: true,
  },
  {
    key: 'has_tube_type',
    label: 'TT, TubeType - камерный тип',
    value: false,
  },
  {
    key: 'has_runflat',
    label: 'RFT, Технология Runflat',
    value: false,
  },
];
